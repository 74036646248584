import InfectedPersonsPrefecture_02 from '../../images/graph/InfectedPersonsPrefecture_02.dot.svg'
import InfectedPersonsPrefecture_03 from '../../images/graph/InfectedPersonsPrefecture_03.dot.svg'
import InfectedPersonsPrefecture_04 from '../../images/graph/InfectedPersonsPrefecture_04.dot.svg'
import InfectedPersonsPrefecture_05 from '../../images/graph/InfectedPersonsPrefecture_05.dot.svg'
import InfectedPersonsPrefecture_06 from '../../images/graph/InfectedPersonsPrefecture_06.dot.svg'
import InfectedPersonsPrefecture_07 from '../../images/graph/InfectedPersonsPrefecture_07.dot.svg'
import InfectedPersonsPrefecture_08 from '../../images/graph/InfectedPersonsPrefecture_08.dot.svg'
import InfectedPersonsPrefecture_09 from '../../images/graph/InfectedPersonsPrefecture_09.dot.svg'
import InfectedPersonsPrefecture_10 from '../../images/graph/InfectedPersonsPrefecture_10.dot.svg'

import InfectedPersonsPrefecture_15 from '../../images/graph/InfectedPersonsPrefecture_15.dot.svg'
import InfectedPersonsPrefecture_16 from '../../images/graph/InfectedPersonsPrefecture_16.dot.svg'

import InfectedPersonsPrefecture_18 from '../../images/graph/InfectedPersonsPrefecture_18.dot.svg'
import InfectedPersonsPrefecture_19 from '../../images/graph/InfectedPersonsPrefecture_19.dot.svg'
import InfectedPersonsPrefecture_20 from '../../images/graph/InfectedPersonsPrefecture_20.dot.svg'
import InfectedPersonsPrefecture_21 from '../../images/graph/InfectedPersonsPrefecture_21.dot.svg'
import InfectedPersonsPrefecture_22 from '../../images/graph/InfectedPersonsPrefecture_22.dot.svg'

import InfectedPersonsPrefecture_24 from '../../images/graph/InfectedPersonsPrefecture_24.dot.svg'
import InfectedPersonsPrefecture_25 from '../../images/graph/InfectedPersonsPrefecture_25.dot.svg'

import InfectedPersonsPrefecture_29 from '../../images/graph/InfectedPersonsPrefecture_29.dot.svg'
import InfectedPersonsPrefecture_30 from '../../images/graph/InfectedPersonsPrefecture_30.dot.svg'
import InfectedPersonsPrefecture_31 from '../../images/graph/InfectedPersonsPrefecture_31.dot.svg'
import InfectedPersonsPrefecture_32 from '../../images/graph/InfectedPersonsPrefecture_32.dot.svg'
import InfectedPersonsPrefecture_33 from '../../images/graph/InfectedPersonsPrefecture_33.dot.svg'
import InfectedPersonsPrefecture_34 from '../../images/graph/InfectedPersonsPrefecture_34.dot.svg'
import InfectedPersonsPrefecture_35 from '../../images/graph/InfectedPersonsPrefecture_35.dot.svg'
import InfectedPersonsPrefecture_36 from '../../images/graph/InfectedPersonsPrefecture_36.dot.svg'
import InfectedPersonsPrefecture_37 from '../../images/graph/InfectedPersonsPrefecture_37.dot.svg'
import InfectedPersonsPrefecture_38 from '../../images/graph/InfectedPersonsPrefecture_38.dot.svg'
import InfectedPersonsPrefecture_39 from '../../images/graph/InfectedPersonsPrefecture_39.dot.svg'
import InfectedPersonsPrefecture_40 from '../../images/graph/InfectedPersonsPrefecture_40.dot.svg'
import InfectedPersonsPrefecture_41 from '../../images/graph/InfectedPersonsPrefecture_41.dot.svg'
import InfectedPersonsPrefecture_42 from '../../images/graph/InfectedPersonsPrefecture_42.dot.svg'
import InfectedPersonsPrefecture_43 from '../../images/graph/InfectedPersonsPrefecture_43.dot.svg'
import InfectedPersonsPrefecture_44 from '../../images/graph/InfectedPersonsPrefecture_44.dot.svg'
import InfectedPersonsPrefecture_45 from '../../images/graph/InfectedPersonsPrefecture_45.dot.svg'
import InfectedPersonsPrefecture_46 from '../../images/graph/InfectedPersonsPrefecture_46.dot.svg'
import InfectedPersonsPrefecture_47 from '../../images/graph/InfectedPersonsPrefecture_47.dot.svg'

export const infectedPersonsPrefectureGraphs = {
    "2" : {
        src: InfectedPersonsPrefecture_02,
        alt: "青森県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "3" : {
        src: InfectedPersonsPrefecture_03,
        alt: "岩手県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "4" : {
        src: InfectedPersonsPrefecture_04,
        alt: "宮城県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "5" : {
        src: InfectedPersonsPrefecture_05,
        alt: "秋田県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "6" : {
        src: InfectedPersonsPrefecture_06,
        alt: "山形県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "7" : {
        src: InfectedPersonsPrefecture_07,
        alt: "福島県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "8" : {
        src: InfectedPersonsPrefecture_08,
        alt: "茨城県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "9" : {
        src: InfectedPersonsPrefecture_09,
        alt: "栃木県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "10" : {
        src: InfectedPersonsPrefecture_10,
        alt: "群馬県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "15" : {
        src: InfectedPersonsPrefecture_15,
        alt: "新潟県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "16" : {
        src: InfectedPersonsPrefecture_16,
        alt: "富山県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "18" : {
        src: InfectedPersonsPrefecture_18,
        alt: "福井県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "19" : {
        src: InfectedPersonsPrefecture_19,
        alt: "山梨県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "20" : {
        src: InfectedPersonsPrefecture_20,
        alt: "長野県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "21" : {
        src: InfectedPersonsPrefecture_21,
        alt: "岐阜県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "22" : {
        src: InfectedPersonsPrefecture_22,
        alt: "静岡県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "24" : {
        src: InfectedPersonsPrefecture_24,
        alt: "三重県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "25" : {
        src: InfectedPersonsPrefecture_25,
        alt: "滋賀県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "29" : {
        src: InfectedPersonsPrefecture_29,
        alt: "奈良県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "30" : {
        src: InfectedPersonsPrefecture_30,
        alt: "和歌山県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "31" : {
        src: InfectedPersonsPrefecture_31,
        alt: "鳥取県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "32" : {
        src: InfectedPersonsPrefecture_32,
        alt: "島根県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "33" : {
        src: InfectedPersonsPrefecture_33,
        alt: "岡山県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "34" : {
        src: InfectedPersonsPrefecture_34,
        alt: "広島県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "35" : {
        src: InfectedPersonsPrefecture_35,
        alt: "山口県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "36" : {
        src: InfectedPersonsPrefecture_36,
        alt: "徳島県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "37" : {
        src: InfectedPersonsPrefecture_37,
        alt: "香川県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "38" : {
        src: InfectedPersonsPrefecture_38,
        alt: "愛媛県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "39" : {
        src: InfectedPersonsPrefecture_39,
        alt: "高知県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "40" : {
        src: InfectedPersonsPrefecture_40,
        alt: "福岡県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "41" : {
        src: InfectedPersonsPrefecture_41,
        alt: "佐賀県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "42" : {
        src: InfectedPersonsPrefecture_42,
        alt: "長崎県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "43" : {
        src: InfectedPersonsPrefecture_43,
        alt: "熊本県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "44" : {
        src: InfectedPersonsPrefecture_44,
        alt: "大分県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "45" : {
        src: InfectedPersonsPrefecture_45,
        alt: "宮崎県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "46" : {
        src: InfectedPersonsPrefecture_46,
        alt: "鹿児島県　新型コロナウイルス(COVID-19)　感染経路図",
    },
    "47" : {
        src: InfectedPersonsPrefecture_47,
        alt: "沖縄県　新型コロナウイルス(COVID-19)　感染経路図",
    },
}
export const infectedPersonsPrefectureGraph = (prefCode) => {

    return infectedPersonsPrefectureGraphs[prefCode]
}