import React, { useState } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import dateformat from 'dateformat'
import styled from '@emotion/styled'
import Paper from '@material-ui/core/Paper';
import GridBase from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import PropTypes from 'prop-types'
import AdSenseBlock from '../../../Atoms/AdSenseBlock'
import * as SVG from '../../../Atoms/SVG'
import Layout from '../../../Templates/Layout'
import Container from '../../../Templates/Container'
import Header from '../../../Templates/Header'
import ReactVirtualizedTable from '../../../Organisms/ReactVirtualizedTable'
import ReactVirtualizedList from '../../../Organisms/ReactVirtualizedList'
import LineBarChart from '../../../Organisms/LineBarChart'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`
const Banner = styled.a`
    display: inline-block;
    padding: 0.5em 1em;
    width: 100%;
    margin: 10px;
    text-decoration: none;
    background: #f7f7f7;
    border-left: solid 6px #ff7c5c;/*左線*/
    color: black;/*文字色*/
    font-weight: bold;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
`

const dataTransform = (org) => {
    const rows = _.map(org, (data) => {
        return {
            ...data.node,
            age: data.node.age.name,
            age_no: data.node.age.no,
            gender_id: data.node.gender.id,
            gender_name: data.node.gender.name,
            confirmation_at: data.node.confirmation_at && dateformat(new Date(data.node.confirmation_at.replace(/-/g, "/")), 'yyyy/mm/dd'),
            prefecture_name: data.node.prefecture.name,
            status_name: data.node.status.name,
            residence: data.node.residence,
            profession: data.node.profession,
            created_at: data.node.created_at && dateformat(new Date(data.node.created_at.replace(/-/g, "/")), 'yyyy/mm/dd'),
            updated_at: data.node.updated_at && dateformat(new Date(data.node.updated_at.replace(/-/g, "/")), 'yyyy/mm/dd'),
        }
    })
    const confirmationAtGrouped = _(rows).uniqBy('confirmation_at').map((row) => {
        return { "confirmation_at": row.confirmation_at }
    }).sortBy('confirmation_at').map((row) => {
        return {
            ...row,
            count: _.filter(rows, ['confirmation_at', row.confirmation_at]).length
        }
    }).value()
    var start = new Date(_(confirmationAtGrouped).minBy("confirmation_at").confirmation_at);
    var end = new Date(_(confirmationAtGrouped).maxBy("confirmation_at").confirmation_at);
    const dates = []
    for (var d = start; d < end; d.setDate(d.getDate() + 1)) {
        dates.push(dateformat(d, 'yyyy/mm/dd'));
    }
    const confirmationAtLineBar = _(dates).map((date) => {
        const data = _(confirmationAtGrouped).find(data => data.confirmation_at == date)
        return {
            confirmation_at: date,
            count: data ? data.count : 0,
            sub_total: _.sumBy(confirmationAtGrouped, (o) => {
                return o.confirmation_at <= date ? o.count : 0
            }),
        }
    }).value()
    const prefectureGrouped = _(rows).uniqBy('pref_code').map((row) => {
        return { "pref_code": row.pref_code, "prefecture_name": row.prefecture_name }
    }).sortBy('pref_code').map((row) => {
        return {
            ...row,
            count: _.filter(rows, ['pref_code', row.pref_code]).length
        }
    }).value()

    const genderGrouped = _(rows).uniqBy('gender_id').map((row) => {
        return { "gender_id": row.gender_id, "gender_name": row.gender_name }
    }).map((row) => {
        const count = _.filter(rows, ['gender_id', row.gender_id]).length
        const rate = rows.length == 0 ? 0 : count / rows.length * 100
        return {
            ...row,
            count: count,
            rate: `${rate.toFixed(1)}%`
        }
    }).value()

    const ageGrouped = _(rows).uniqBy('age').map((row) => {
        return { "age": row.age, 'age_no': row.age_no }
    }).sortBy('age_no').map((row) => {
        const count = _.filter(rows, ['age', row.age]).length;
        const rate = rows.length == 0 ? 0 : count / rows.length * 100
        return {
            ...row,
            count: count,
            rate: `${rate.toFixed(1)}%`
        }
    }).value()

    const professionGrouped = _(rows).uniqBy('profession').map((row) => {
        return { "profession": row.profession }
    }).sortBy('profession').map((row) => {
        return {
            ...row,
            count: _.filter(rows, ['profession', row.profession]).length
        }
    }).value()
    const updateAt = _(rows).map((row) => {
        return { "update_at": row.update_at > row.created_at ? row.updateAt : row.created_at }
    }).maxBy('update_at').update_at//, _(rows).maxBy('updated_at').updated_at
    return {
        rows: rows,
        confirmationAtGrouped: confirmationAtGrouped,
        prefectureGrouped: prefectureGrouped,
        genderGrouped: genderGrouped,
        ageGrouped: ageGrouped,
        professionGrouped: professionGrouped,
        updateAt: updateAt,
        confirmationAtLineBar: confirmationAtLineBar
    }

}

const InfectedPersonCard = (props) => {
    const { prefecture_name, no } = props.data
    const { gender_name, age, nationality, municipality, profession, status_name } = props.data
    const { start_at, confirmation_at, dead_at, end_at } = props.data
    const { residence } = props.data
    return (
        <Card style={{ margin: "5px", maxHeight: "290px", overflow: 'auto' }}>
            <CardContent>
                <Typography variant="h5" component="h2"> {prefecture_name}:{no}例目の感染者</Typography>
                <Typography variant="body2" component="p"> 性別: {gender_name}</Typography>
                <Typography variant="body2" component="p"> 年齢: {age} </Typography>
                <Typography variant="body2" component="p"> 国籍: {nationality} </Typography>
                <Typography variant="body2" component="p"> 都市: {municipality} </Typography>
                <Typography variant="body2" component="p"> 職業: {profession} </Typography>
                <Typography variant="body2" component="p"> 状態: {status_name} </Typography>
                <Typography variant="body2" component="p"> 症状自覚日: {start_at} </Typography>
                <Typography variant="body2" component="p"> 感染確認日: {confirmation_at} </Typography>
                <Typography variant="body2" component="p"> 陰性確認日: {end_at} </Typography>
                { dead_at && <Typography variant="body2" component="p"> 死亡日: {end_at} </Typography> }
                <Typography variant="body2" component="p"> メモ: {residence} </Typography>
            </CardContent>
        </Card>
    );
}

const InfectedPersonsPrefecture = ({ data, pageContext }) => {
    const {
        rows,
        confirmationAtGrouped,
        prefectureGrouped,
        genderGrouped,
        ageGrouped,
        professionGrouped,
        updateAt,
        confirmationAtLineBar
    } = dataTransform(data.allCovid19.edges)

    const { prefectureCode } = pageContext
    const prefectureName = rows[0].prefecture_name
    const title = `${prefectureName}のCOVID-19(新型コロナウイルス)感染状況 | Not Be Forgotten`
    const description = `COVID-19(新型コロナウイルス)の${prefectureName}感染状況を随時更新していきます`
    const imagePath = data.file ? data.file.publicURL : ""
    const seoProps = {
        title: title,
        desc: description,
        image: imagePath,
        pathname: "",
        article: false
    }
    const headerProps = {
        title: title,
        data: {},
        cover: "",
    }
    const graphSVG = SVG.infectedPersonsPrefectureGraph(prefectureCode)
    const [tabIndex, setTabIndex] = useState(0);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    return (
        <Layout seo={seoProps}>
            <Header {...headerProps} />
            <Container>
                <Grid container spacing={1} p={1} m={5}>
                    <Grid item xs={12} md={12}>
                        {prefectureName}のCOVID-19(新型コロナウイルス)感染者に関する情報を日々更新しています。<br />
                        現在は、{rows.length}人の感染者が確認されています。<br />
                        更新日: {updateAt}
                    </Grid>
                    <Grid container spacing={1} p={1} m={5}>
                        <Grid item xs={12} md={12}>
                            <Banner href="/app/inspection_performance">検査シミュレーターを作りました！</Banner>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Banner href="/app/covid19/poster-generator">コロナ対策のチェックリストを作りました！</Banner>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} md={6}><AdSenseBlock id={1} /></Grid>
                    <Grid item xs={6} md={6}><AdSenseBlock id={2} /></Grid>
                    <Grid item xs={12} md={12}>
                        <Tabs value={tabIndex} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="感染経路図" id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`} />
                            <Tab label="サマリ" id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`} />
                            <Tab label="データ" id={`simple-tab-2`} aria-controls={`simple-tabpanel-3`} />
                        </Tabs>
                    </Grid>
                </Grid>
                <div hidden={tabIndex !== 0}>
                    <Grid container spacing={1} p={1} m={5}>
                        <Grid item xs={12} md={12}>
                            {
                                graphSVG &&
                                <Grid item xs={12} md={12}>
                                    <h2>{prefectureName}の感染経路図</h2>
                                    <img src={graphSVG.src} alt={graphSVG.alt} width="100%" />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div hidden={tabIndex !== 1}>
                    <Grid container spacing={1} p={1} m={5}>
                        <Grid item xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <LineBarChart
                                    width={500}
                                    height={250}
                                    data={confirmationAtLineBar}
                                    key_x="confirmation_at"
                                    y={
                                        {
                                            line: {
                                                key: "sub_total",
                                                name: "累計感染確認者数"
                                            },
                                            bar: {
                                                key: "count",
                                                name: "新規感染確認者数"
                                            },
                                        }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={12} spacing={1}>
                        <Grid item xs={12} md={6}>
                            <h2>{prefectureName}の感染確認日別感染者</h2>
                            <Paper style={{ height: confirmationAtGrouped.length > 14 ? 600 : confirmationAtGrouped.length * 40 + 100, width: '100%', padding: '5px' }}>
                                <ReactVirtualizedTable
                                    data={confirmationAtGrouped}
                                    columns={[
                                        {
                                            width: 200,
                                            label: '感染確認日',
                                            dataKey: 'confirmation_at',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h2>{prefectureName}の性別別感染者</h2>
                            <Paper style={{ height: genderGrouped.length > 14 ? 600 : genderGrouped.length * 40 + 100, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedTable
                                    data={genderGrouped}
                                    columns={[
                                        {
                                            width: 200,
                                            label: '性別',
                                            dataKey: 'gender_name',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true
                                        },
                                        {
                                            width: 300,
                                            label: '割合',
                                            dataKey: 'rate',
                                            numeric: true
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h2>{prefectureName}の年代別感染者</h2>
                            <Paper style={{ height: ageGrouped.length > 14 ? 600 : ageGrouped.length * 40 + 100, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedTable
                                    data={ageGrouped}
                                    columns={[
                                        {
                                            width: 200,
                                            label: '年代',
                                            dataKey: 'age',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true
                                        },
                                        {
                                            width: 300,
                                            label: '割合',
                                            dataKey: 'rate',
                                            numeric: true
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h2>{prefectureName}の職業別感染者</h2>
                            <Paper style={{ height: professionGrouped.length > 14 ? 600 : professionGrouped.length * 40 + 100, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedTable
                                    data={professionGrouped}
                                    columns={[
                                        {
                                            width: 200,
                                            label: '職業',
                                            dataKey: 'profession',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div hidden={tabIndex !== 2}>
                    <Grid container item xs={12} md={12} spacing={1} >
                        <Grid item xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <h2>{prefectureName}の感染者一覧</h2>
                                <Paper style={{ height: 700, width: '100%', overflowX: 'auto' }}>
                                    <ReactVirtualizedTable
                                        data={rows}
                                        columns={[
                                            {
                                                width: 100,
                                                label: 'No.',
                                                dataKey: 'no',
                                                numeric: true,
                                            },
                                            {
                                                width: 200,
                                                label: '感染確認日',
                                                dataKey: 'confirmation_at',
                                            },
                                            {
                                                width: 120,
                                                label: 'ステータス',
                                                dataKey: 'status_name',
                                            },
                                            {
                                                width: 120,
                                                label: '性別',
                                                dataKey: 'gender_name',
                                            },
                                            {
                                                width: 120,
                                                label: '年齢',
                                                dataKey: 'age',
                                            },
                                            {
                                                width: 120,
                                                label: '国籍',
                                                dataKey: 'nationality',
                                            },
                                            {
                                                width: 120,
                                                label: '職業',
                                                dataKey: 'profession',
                                            },
                                        ]}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <h2>感染者詳細一覧</h2>
                                <div style={{ height: 700, width: '100%', overflowX: 'auto' }}>
                                    <ReactVirtualizedList
                                        data={rows}
                                        noRowsRenderer={() => { }}
                                        renderRow={(key, style, data) => {
                                            return (
                                                <div key={key} style={style}>
                                                    <InfectedPersonCard data={data} />
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Grid container spacing={1} p={1} m={5}>
                    <Grid item xs={6} md={6}><AdSenseBlock id={1} /></Grid>
                    <Grid item xs={6} md={6}><AdSenseBlock id={2} /></Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default InfectedPersonsPrefecture

InfectedPersonsPrefecture.propTypes = {
    data: PropTypes.object.isRequired
}

export const query = graphql`
query InfectedPersonsPrefecture($prefectureCode : Int!, $ogpPath: String!) {
    file(relativePath: {regex: $ogpPath}) {
        publicURL
    }
    allCovid19(
        filter: {pref_code: {eq: $prefectureCode}}
    )  {
      edges {
        node {
          id
          covid19_id
          status_id
          age {
            id
            name
            no
          }
          gender {
            id
            name
          }
          nationality
          no
          pref_code
          municipality
          prefecture {
            id
            name
            pref_code
          }
          status {
            id
            name
          }
          profession
          residence
          confirmation_at
          start_at
          dead_at
          end_at
          information_source
          created_at
          updated_at
        }
      }
    }
  }
`